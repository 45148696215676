import { Container, Row, Col, Card, Button, Accordion } from "react-bootstrap";
import CanvasParentPwReset from "../../assets/img/LMS/Canvas-Parent-PW-Reset-Steps.png";
import { useEffect } from "react";

const Lms = () => {
	useEffect(() => {
		document.title = "Learning Management System | Diocese of Lincoln Schools";
	});

	return (
		<>
			<Container className="bg-light text-secondary p-1 text-center">
				<h1 className="text-primary">
					Canvas Learning Management System {"(LMS)"}
				</h1>
				<p className="lead">
					Welcome to our LMS landing page. If you have questions or issues with
					logging in, please view the <a href="#questions">FAQ</a> or contact
					your school for assistance.
				</p>
			</Container>
			<Container>
				<Row className="row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									Parents
								</Card.Title>
								<Button
									href="/lms/parent_account_setup"
									className="m-2"
								>
									Canvas Account Setup
								</Button>
								<Button
									href="https://cdol.instructure.com/login/canvas"
									target="_Blank"
									className="m-2"
								>
									Returning Parents
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									Students, Faculty, & Staff
								</Card.Title>
								<Button
									href="https://cdol.instructure.com/login/saml"
									target="_Blank"
									className="m-2"
								>
									Click here to login
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									Mobile App Setup
								</Card.Title>
								<Button
									href="/lms/mobile_app_setup"
									className="m-2"
								>
									Click here
								</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<Container className="p-4">
				<h2 className="mb-4">Frequently Asked Questions</h2>
				<Accordion id="questions">
					<Accordion.Item eventKey="0">
						<Accordion.Header>Is there a mobile app?</Accordion.Header>
						<Accordion.Body className="py-5">
							Yes, there are separate apps for Parents, Students, and Teachers.{" "}
							<a
								href="LMS/mobile_app_setup"
								target="_blank"
							>
								Click here to view the setup instructions.
							</a>
							..
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>I'm a parent, how do I login?</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								<u>
									You must register for an account to observe your children in
									Canvas as a parent/guardian.
								</u>
							</p>
							<ul>
								<li>
									<strong>
										Additionally, you must have a student pairing code to
										complete the setup process.
									</strong>
								</li>
								<li>
									<strong>
										Pairing codes will not be available until the start of the
										school year.
									</strong>
								</li>
								<li>
									<a href="LMS/parent_account_setup">
										Click here to view the parent account setup instructions.
									</a>
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							I'm a parent AND a faculty/staff member, do I need a separate
							parent account?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								<strong>Yes!</strong> It is vital you use a separate email
								address for your parent account if you are faculty/staff.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							I'm a student, faculty, or staff member. How do I login?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								Use your Microsoft 365 credentials to login. This will be your
								school email address and password.{" "}
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header>
							I cannot login, what should I do?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								If you are a <u>student, faculty, or staff member</u> please
								contact your school tech liaison for assistance.
							</p>
							<p>
								If you are a <u>parent</u>, remember you can reset your password
								on the{" "}
								<a
									href="https://cdol.instructure.com/login/canvas"
									target="_blank"
									rel="noreferrer"
								>
									login page.
								</a>{" "}
								Additionally, you may{" "}
								<a
									target="_blank"
									href="https://community.canvaslms.com/t5/Observer-Guide/How-do-I-reset-my-password-as-an-observer/ta-p/570"
									rel="noreferrer"
								>
									follow these instructions to reset your password.
								</a>
							</p>
							<img
								className="img-fluid p-3"
								src={CanvasParentPwReset}
								alt="Parent Password Reset Screenshot"
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header>
							I have questions about Pairing Codes. Where can I find more
							information?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								Pairing codes can be generated by school administrators, tech
								liaisons, teachers, or students.
							</p>
							<p>
								More information can be found by visiting the{" "}
								<a
									target="_blank"
									href="https://community.canvaslms.com/t5/Canvas-Resource-Documents/Pairing-Codes-FAQ-PDF/ta-p/388738"
									rel="noreferrer"
								>
									Canvas Pairing Codes-FAQ
								</a>
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="6">
						<Accordion.Header>
							Are there guides for Parents, Students & Staff?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								Yes, Canvas has a great collection of guides for everyone to
								follow.
							</p>
							<ul>
								<li>
									Parents,{" "}
									<a
										target="_blank"
										href="https://community.canvaslms.com/t5/Canvas-Observer-Parent/ct-p/canvas_observer"
										rel="noreferrer"
									>
										click here to visit the guide.
									</a>
								</li>
								<li>
									Students,{" "}
									<a
										target="_blank"
										href="https://community.canvaslms.com/t5/Canvas-Student/ct-p/canvas_student"
										rel="noreferrer"
									>
										click here to visit the guide.
									</a>
								</li>
								<li>
									Faculty & Staff,{" "}
									<a
										target="_blank"
										href="https://community.canvaslms.com/t5/Canvas-Instructor/ct-p/canvas_instructor"
										rel="noreferrer"
									>
										click here to visit the guide.
									</a>
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="7">
						<Accordion.Header>
							Can I bypass this page and go directly to the login screen?
						</Accordion.Header>
						<Accordion.Body className="py-5">
							<p>
								Yes, you can bookmark the following links to go directly to the
								login page.
							</p>
							<ul>
								<li>
									Parents:{" "}
									<a
										target="_blank"
										href="https://cdol.instructure.com/login/canvas"
										rel="noreferrer"
									>
										https://cdol.instructure.com/login/canvas
									</a>
								</li>
								<li>
									Students, Faculty, & Staff:{" "}
									<a
										target="_blank"
										href="https://cdol.instructure.com/login/saml"
										rel="noreferrer"
									>
										https://cdol.instructure.com/login/saml
									</a>
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Container>
		</>
	);
};
export default Lms;

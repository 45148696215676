import { Container } from 'react-bootstrap'
import ParentPortalForgot from '../../assets/img/Enrollment/parent_portal_forgot.png'
import ParentPortalAddStudent from '../../assets/img/Enrollment/parent_portal_add_student.png'
import ParentPortalAdd from '../../assets/img/Enrollment/parent_portal_add.png'
import { useEffect } from 'react'
import SubNav from '../../components/SubNav'

const ParentPortal = () => {
	useEffect(() => {
		document.title = 'Adding Student to Existing PowerSchool Parent Account | Diocese of Lincoln Schools'
	})

	return (
		<>
			<SubNav />
			<Container className='mb-5 pt-4 full-width-div'>
				<>
					<h1>PowerSchool Parent Portal Setup</h1>
					<p className='lead'>
						The following directions are intended for parents who would like to add a new student to your existing PowerSchool Parent account.
						<br />
						The parent portal is an online portal accessible anywhere on the web. Parents can log in to and see all of their children in one place, and monitor grades, assignments, scores, attendance,
						schedules, and school bulletins for each school your children attend.
						<br />
						If you don't have a PowerSchool Parent Portal account, please follow this link to the <a href='new_parent_portal_setup'>Create a new PowerSchool Parent Account</a> page.
					</p>
				</>
			</Container>
			<Container
				id='add'
				className='mb-5'
			>
				<h2 className='pt-4'>Adding Student to Existing PowerSchool Parent Account</h2>
				<p className='lead'>Follow these instructions if you already have a PowerSchool Parent Account and need to add a new student.</p>
				<ol>
					<li>Open any internet browser on your computer (Chrome, Firefox, Edge, Etc.)</li>
					<li>
						Type{' '}
						<a
							href='https://powerschool.cdolinc.net/'
							target='_Blank'
							rel='noreferrer'
						>
							https://powerschool.cdolinc.net/
						</a>
						into the address bar
					</li>
					<li>
						Type in the <strong>Username</strong> and <strong>Password</strong> used to log into your PowerSchool Parent Account. Then click the <strong>Sign In</strong>
						button
						<ul>
							<li>
								If you do not remember your <strong>Username</strong> click the <em>Forgot Username</em> link under the <strong>Parent Account Recovery Links</strong> section at the bottom of the
								screen
							</li>
							<li>
								If you do not remember your <strong>Password</strong> click the <em>Forgot Password</em> link under the <strong>Parent Account Recovery Links</strong> section at the bottom of the
								screen
							</li>
						</ul>
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalForgot}
					alt='Parent Portal Forgot'
				/>
				<ol start='4'>
					<li>
						Then click on <strong>Account Preferences</strong> link toward the bottom of the Left Navigation
					</li>
					<li>
						On the <em>Account Preferences</em> Click on the <strong>Students</strong> Tab
					</li>
					<li>
						On the <em>Students</em> Click on the <strong>Add</strong> Button
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalAddStudent}
					alt='Parent Portal Add Student'
				/>
				<p>
					You should have received a letter from your school with your student's <strong>Access ID</strong> and <strong>Access Password</strong>. You will need that letter for the next step. If you
					don't have your student's <strong>Access ID</strong> and <strong>Access Password</strong>, please contact your local school. They will provide you with a letter that contains your student's{' '}
					<strong>Access ID</strong> and <strong>Access Password</strong>.
				</p>
				<ol start='7'>
					<li>
						On the <em>Add Student</em> window that opens
						<ol>
							<li>
								<strong>Student Name</strong> - Type the <u> Student's</u> name
								<ul>
									<li>LastName, FirstName works best</li>
									<li>
										It should also recognize FirstName <em>space</em>
										LastName
									</li>
								</ul>
							</li>
							<li>
								<strong>Access ID</strong> - Type the <u>Access ID</u> the for your student from the letter given from your school
								<ul>
									<li>If you do not have this letter, please contact your school.</li>
								</ul>
							</li>
							<li>
								<strong>Access Password </strong> - Type the <u>Access Password</u> for your student from the letter given from your school
								<ul>
									<li>If you do not have this letter, please contact your school.</li>
								</ul>
							</li>
							<li>
								<strong>Relationship</strong> - Choose your relationship to the student from the dropdown
							</li>
						</ol>
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalAdd}
					alt='Parent Portal Add'
				/>
				<ol start='8'>
					<li>Repeat Step 6 and 7 for each additional student you want to add to your account</li>
				</ol>
			</Container>
		</>
	)
}
export default ParentPortal

import React from "react";
import SubNav from "../../components/SubNav";
import {
	Container,
	Tab,
	Tabs,
	Col,
	Row,
	Image,
	Accordion,
} from "react-bootstrap";
import MFABackupiOS from "../../assets/img/MFA/MFA_Backup_iOS.png";
import MFABackupAndroid from "../../assets/img/MFA/MFA_Backup_Android.png";
import MFANumberMatch from "../../assets/img/MFA/MFA_Number_Match.png";
import MFAActionReq1 from "../../assets/img/MFA/MFA_Action_Required.png";
import MFAActionReq2 from "../../assets/img/MFA/MFA_Action_Required2.png";

const MFAHelpInstructions = ({ MFAHelpPath }) => {
	return (
		<>
			<SubNav />
			<Container className="mb-5 pt-4 full-width-div">
				{MFAHelpPath === "MFA Backup" ? (
					<>
						<h1>Choose your Backup Method</h1>
						<p className="lead">
							We strongly recommend you backup both your mobile device and the
							authenticator app. This will ensure you can recover your account
							in the event of a lost or stolen mobile device.
						</p>
						<p className="">
							Choose <a href="#optionA">Option A</a> for instructions on how to
							backup and restore your mobile device, or choose{" "}
							<a href="#optionB">Option B</a> for instructions on how to backup
							the authenticator app. Ideally you should do both.
						</p>
						<h2 id="optionA">
							Option A: Backup and Restore your Mobile Device (Recommended)
						</h2>
						<p className="lead">
							Backing up your mobile device is completely dependent on which
							manufacturer you choose, and how you like to backup your device.
							Regardless, backing up and restoring your mobile device is the
							recommended method. When done correctly, your new device should
							appear and function just like your old device. This means less
							time recovering accounts and setting up apps. We recommend
							following the instrutions below, or reaching out to your device
							manufacturer for additional support to backup your old device and
							restore your new device. Additionaly, your cellular service
							provider may offer backup solutions as well.
						</p>
						<table className="table lead mb-4 mt-4 ">
							<thead>
								<tr>
									<th>Manufacturer</th>
									<th>Backup and Restore Instructions</th>
									<th>Support Website</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Apple</td>
									<td>
										<a
											href="https://support.apple.com/en-us/HT207428"
											target="blank"
										>
											Backup and Restore using iCloud
										</a>{" "}
										or{" "}
										<a
											href="https://support.apple.com/en-us/HT201269"
											target="blank"
										>
											iTunes
										</a>
									</td>
									<td>
										<a
											href="https://support.apple.com/"
											target="blank"
										>
											Apple Support
										</a>
									</td>
								</tr>
								<tr>
									<td>Samsung</td>
									<td>
										<a
											href="https://www.samsung.com/us/support/answer/ANS00060642/"
											target="blank"
										>
											Samsung Smart Switch
										</a>
									</td>
									<td>
										<a
											href="https://www.samsung.com/us/support/"
											target="blank"
										>
											Samsung Support
										</a>
									</td>
								</tr>
								<tr>
									<td>Google (Pixel)</td>
									<td>
										<a
											href="https://support.google.com/pixelphone/answer/2819582"
											target="blank"
										>
											Backup and Restore with Google One
										</a>
									</td>
									<td>
										<a
											href="https://support.google.com/pixelphone/"
											target="blank"
										>
											Google Pixel Help
										</a>
									</td>
								</tr>
							</tbody>
						</table>
						<h2 id="optionB">Option B: Backup Authenticator App</h2>
						<p className="lead">
							The following directions are intended for anyone who has setup MFA
							and wants to backup or restore their authenticator app on a new
							device. There are a few pre-requisites.
							<ul>
								<li>
									You must have a personal microsoft account to act as your
									recovery account. If you do not have one, you can make{" "}
									<b>any email address (except work/school email addresses) </b>{" "}
									a "Microsoft Account" by signing up for a free account at{" "}
									<a
										href="https://account.microsoft.com/account"
										target="blank"
									>
										account.microsoft.com/account
									</a>
								</li>
								<li>
									For iOS users, you will need to have an iCloud account for the
									storage of your backup. This is typically your Apple ID.
								</li>
							</ul>
						</p>
						<Tabs
							defaultActiveKey="install"
							id="Step 1: Open Microsoft Authenticator"
							className="mb-4 mt-4 nav-pills flex-column flex-sm-row bg-light shadow-lg"
							justify
						>
							<Tab
								eventKey="install"
								title="Step 1: Backup Authenticator"
								className="fw-bold"
							>
								<Container className="lead container-fluid p-flex">
									<Row className="p-2">
										<ol type="A">
											<li>
												Before you transfer devices, open Microsoft
												Authenticator on your old device and go to settings.
											</li>
											<li>
												Turn on the backup feature and, if necessary, sign in
												with a personal Microsoft account. This will backup your
												accounts to the cloud.
											</li>
											<li>
												Take note of the recovery account. This is the email
												address you will use to recover your accounts on your
												new mobile device.
											</li>
										</ol>
									</Row>
									<Row className="p-2">
										<Col className="p-2">
											iPhone
											<Image
												src={MFABackupiOS}
												className="mx-lg-auto img-fluid"
												alt="iOS Authenticator App Backup Instructions"
											></Image>
										</Col>
										<Col className="p-2">
											Android
											<Image
												src={MFABackupAndroid}
												className="mx-lg-auto img-fluid"
												alt="Android Authenticator App Backup Instructions"
											></Image>
										</Col>
									</Row>
								</Container>
							</Tab>
							<Tab
								eventKey="restore"
								title="Step 2: Restore or Recover Accounts"
								className="fw-bold"
							>
								<Container className="lead p-flex">
									<Row className="p-2">
										<ol type="A">
											<li>
												After you have your new mobile device, download
												Microsoft Authenticator.
											</li>
											<li>
												<mark>
													<b>Important: </b>Select begin recovery before signing
													in.
												</mark>
											</li>
											<li>
												Sign in using the recovery email address noted in step
												one.
											</li>
										</ol>
									</Row>
								</Container>
							</Tab>
							<Tab
								eventKey={"Additional Verification"}
								title="Step 3: Additional Verification"
								className="fw-bold"
							>
								<Container className="lead p-flex">
									<Col className="p-2">
										<ol type="A">
											<li>
												Because you have a work or school account you will need
												to provide additional verification after restoring your
												account. See screenshots below for an example.
											</li>
											<li>
												To accomplish this you will need to sign in to your work
												or school account on a PC and go to{" "}
												<a
													href="https://mysignins.microsoft.com/security-info"
													target="blank"
												>
													https://mysignins.microsoft.com/security-info
												</a>{" "}
												and scan the QR code with your new mobile device.
											</li>
											<li>
												On your mobile device, in the Authenticator app navigate
												to the work or school account that you wish to recover.
												Tap on the account, then tap the option to scan a QR
												code to fully recover.
											</li>
											<li>
												Scan the QR code on your PC to fully recover your
												account.
											</li>
										</ol>{" "}
									</Col>
									<Col className="mb-2 p-2">
										<Image
											src={MFAActionReq1}
											className="img-fluid"
										></Image>
										<Image
											src={MFAActionReq2}
											className="img-fluid"
										></Image>
									</Col>
								</Container>
							</Tab>
						</Tabs>
						<p>
							Instruction and image &copy;Microsoft. See{" "}
							<a href="https://support.microsoft.com/en-us/account-billing/back-up-and-recover-account-credentials-in-the-authenticator-app-bb939936-7a8d-4e88-bc43-49bc1a700a40">
								Back up and recover account credentials in the Authenticator
							</a>{" "}
							for original content.
						</p>
					</>
				) : MFAHelpPath === "MFA Lockout" ? (
					<>
						<h1>Locked Out?</h1>
						<p className="lead">
							The most common cause of getting locked out is when you trade in a
							mobile device for a new one. If you feel you are locked out of
							your account, or are having trouble with MFA, please contact your
							school tech liaison and start a ticket with the Education
							Technology Department. We will reach out to you as soon as
							possible. The technology department staff can reset or remove
							mobile devicess from your account, and help you get back into your
							account. This will require you setup MFA again.
						</p>
						<h2>Can't I just delete the app and start over?</h2>
						<p className="lead">
							No, removing the app deletes all registered accounts and is not
							recommended. The best thing you can do is contact the technology
							department staff for how to proceed.
						</p>
						<h2>
							Email stopped working on my mobile device but other things work,
							what should I do?
						</h2>
						<p className="lead">
							You must login and enter your MFA code for email to work properly.
							If you've dismissed these notifications to sign in, your email may
							stop functioning. On your mobile device you can remove and re-add
							your account to the email settings. Make sure to enter the MFA
							code when prompted and all should be fixed. If not, please contact
							us.
						</p>
					</>
				) : MFAHelpPath === "MFA FAQ" ? (
					<>
						<Container
							className="lead p-4"
							id="FAQ"
						>
							<h1 className="mb-4">Frequently Asked Questions</h1>
							<Accordion id="mfafaq">
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										I'm locked out should I delete the authenicator app and
										start over?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p className="lead">
											<b>No.</b> Deleting the authenticator app will not help
											you get back into your account.
											<ul>
												<li>
													There may be other accounts associated with your
													authenticator app. Deleting the app deletes all data
													and accounts you have setup.
												</li>
												<li>
													If you are locked out, or are having trouble with MFA,
													please contact your school tech liaison and start a
													ticket with the Education Technology Department.
												</li>
												<li>
													The technology department staff can reset or remove
													mobile devicess from your account, and help you get
													back into your account. This will require you setup
													MFA again.
												</li>
												<li>
													If you are going to delete the app, make sure you
													backup your authenticator accounts first. Instructions
													for how to backup can be found{" "}
													<a href="/MFA/MFAHelp/mfahelp_backup">here.</a>
												</li>
											</ul>
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										My email stopped working on my mobile device but other
										things work, what should I do?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p className="lead">
											For email to function properly on your mobile device you
											must login and enter your MFA code. If you've dismissed
											these notifications to sign in, your email may stop
											functioning. To fix this simply remove the email account
											and re-add it to your device. Make sure to enter the MFA
											code when prompted and all should be fixed. If not, please
											contact us.
											<ul>
												<li>
													<b>Note: do not delete the authenticator app. </b>
													Deleting the app will not help you get back into your
													email.
												</li>
											</ul>
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>
										What does MFA look like? How do I use it?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p className="lead">
											Say you are going to check your email at home so you enter
											your work email and password. <u>Without MFA</u> that's
											all you would need, and <u>anyone</u> with your
											credentials could log in.{" "}
											<b>
												With MFA enabled when signing in from an unfamiliar or
												new device, such as a home computer, you will be
												prompted to enter a six digit code or matching number,
												to verify your identity.
											</b>
										</p>
										<ul className="lead">
											<li>
												If you are using the Microsoft Authenticator app, you
												simply open the app on your mobile device and it will
												show you a 6-digit dynamically created number or it will
												ask you to match a two digit number. Enter either of
												these numbers and you're in.
											</li>
											<li>
												If you are using a hardware authenticator the process is
												similar. Just push the button on the hardware
												authenticator to display the 6-digit one-time password
												(OTP) number and enter it into the site.
											</li>
										</ul>
										<p className="lead">Here's what that may look like.</p>
										<Image
											src={MFANumberMatch}
											className="mx-lg-auto img-fluid"
											alt="Multifactor Authentication Number Matching Example"
										></Image>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>
										Why am I asked for a code?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											One Time Passwords, known as OTP codes, have been around
											for many years as a way to secure sensitive accounts and
											information. This was commonly done via email or sms text
											messages.
										</p>
										<p>
											Now we have apps that can do this for us. Part of having
											MFA enabled on your account involves using OTP codes to
											prove who you are.
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>
										Why do I need notifications turned on?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											The Microsoft Authenticator app has several features that
											make it easy to get into and secure your account.
										</p>
										<ul>
											<li>
												It can notify you and ask if it is you signing into your
												account. If it is not you signing in, you may deny
												access and lock your account.
											</li>
											<li>
												Notifications make it easy to open the app and access
												codes.
											</li>
											<li>
												At times you may be asked to match numbers rather than
												enter an entire code. This is easy to do with
												notifications from the app.
											</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>
										Why do I need to use a pin code or biometrics with the
										Authenticator app?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											Just like your passwords, the Authenticator app should be
											kept private. Enabling a pin code, fingerprint, or facial
											recognition makes it easy to secure your Authenticator
											app.
										</p>
										<p>
											Additionally, this prevents anyone else from using your
											authenticator, making your account very secure.
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="6">
									<Accordion.Header>
										I heard MFA can be hacked? Is that true?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											<b>Yes!</b> However, do not despair. Hackers love easy
											targets. While MFA isn't hack-proof, it is better than a
											password alone, and a good step toward stronger account
											security.
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="7">
									<Accordion.Header>
										Can I use Microsoft Authenticator with my other accounts?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											Yes, Microsoft Authenticator works with any account that
											supports two-factor or multi-factor authentication and
											each account is separate from the other. Unique codes are
											generated for each account you add to the Authenticator.
											If you do use Authenticator with other accounts we
											strongly recommend you backup your accounts to the cloud.
											This will ensure you can recover your account in the event
											of a lost or stolen mobile device. See instructions on{" "}
											<a href="/MFA/MFAHelp/mfahelp_backup">
												how to backup and restore your mobile device or the
												authenticator app here.
											</a>
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="8">
									<Accordion.Header>
										Do I need to install the authenticator app on more than one
										device?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											<b>No.</b> Installing authenticator on multiple devices
											can cause issues in receiving OTP codes and notifications.
										</p>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="9">
									<Accordion.Header>
										Can I setup MFA without my PC?
									</Accordion.Header>
									<Accordion.Body className="py-5">
										<p>
											Yes, it is possible to setup MFA via you mobile device,
											but it is a lot easier to make a mistake. If you are
											interested in how to do this from your mobile device
											please view{" "}
											<a href="https://support.microsoft.com/en-us/account-billing/work-or-school-account-help-718b3d92-a8a7-4656-8a05-c0228d346b7d">
												{" "}
												Microsoft's support documentation.
											</a>
										</p>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Container>
					</>
				) : MFAHelpPath === "MFA Token" ? (
					<>
						<h2>What is a Token?</h2>
						<p className="lead">
							A token is a small hardware device that you carry with you. It
							provides a unique code that changes every 30 seconds. When you
							sign in, you enter your password and the code from the token. This
							provides an extra layer of security.
						</p>
						<p className="lead">
							If you are interested in using a token, and cannot utilize a
							mobile device, please contact your school tech liaison and start a
							ticket with the Education Technology Department. We will reach out
							to you as soon as possible.
						</p>
						<h2>I'm having issues with my token, can it be reset?</h2>
						<p className="lead">
							Yes, if your token isn't functioning properly we can try resetting
							it. If that doesn't work we can issue a new token to you. Contact
							your school tech liaison and start a ticket.
						</p>
						<h2>My token battery is low what should I do?</h2>
						<p className="lead">
							Hardware token batteries should last up to five years. If you find
							the token charge is low or expended please contact your school
							tech liaison and start a ticket. We will issue a new token to you
							as soon as possible.
						</p>
					</>
				) : null}
			</Container>
		</>
	);
};

export default MFAHelpInstructions;

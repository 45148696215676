import { Container, Row, Col, Button, Accordion } from 'react-bootstrap'
import ReturningStudentImg from '../../assets/img/Enrollment/returningstudent_site.png'
import NewStudentImg from '../../assets/img/Enrollment/newstudent_site.png'
import ForgotUn from '../../assets/img/Enrollment/forgot_username.png'
import ForgotPw from '../../assets/img/Enrollment/forgot_password.png'
import PwReset from '../../assets/img/Enrollment/parent_password_reset.png'
import TooMany from '../../assets/img/Enrollment/contact_too_many.png'
import { useEffect } from 'react'

const Enrollment = () => {
	useEffect(() => {
		document.title = 'Online Student Enrollment | Diocese of Lincoln Schools'
	})

	return (
		<>
			<Container className='bg-light text-secondary p-1 text-center'>
				<h1 className='text-primary'>Online Student Enrollment</h1>
				<p class='lead'>
					If you have questions or issues with the enrollment process please contact your
					<a
						className='ps-1'
						rel='noreferrer'
						href='http://www.lincolndiocese.org/our-schools/school-directory'
						target='_blank'
					>
						local school
					</a>
				</p>
			</Container>
			<Container>
				<Row className='d-lg-none d-xl-none text-center align-items-center g-2'>
					<Col md></Col>
					<Col md>
						<Button
							href='#returning'
							variant='primary'
						>
							Returning Student
						</Button>
					</Col>
					<Col md>
						<Button
							href='#new'
							variant='primary'
						>
							New Student
						</Button>
					</Col>
					<Col md></Col>
				</Row>
			</Container>
			<Container
				id='returning'
				className='my-2'
			>
				<Row className='p-4 pb-0 pe-lg-0 pt-lg-4 align-items-center rounded-3 border shadow-lg'>
					<Col className='col-lg-7 p-3 p-lg-5 pt-lg-3'>
						<h1 className='display-4 fw-bold lh-1'>Returning Student Enrollment</h1>
						<p className='lead'>
							If your child(ren) are already enrolled in one of the Diocese of Lincoln Schools, you will need to re-enroll them for the new school year through your PowerSchool Parent Portal Account.
							Please click the button below for instructions.
						</p>
						<div className='d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3'>
							<Button
								href='/enrollment/returning_instructions'
								variant='primary'
								className='px-4 px-4 me-md-2'
								size='lg'
							>
								Returning Student Enrollment Instructions
							</Button>
						</div>
					</Col>
					<Col className='col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg d-none d-lg-block'>
						<img
							className='rounded-lg-3 d-none d-lg-block'
							src={ReturningStudentImg}
							alt='Returning Student Site'
							width='720'
						/>
					</Col>
				</Row>
			</Container>
			<Container
				id='new'
				className='my-2'
			>
				<Row className='p-4 pb-0 pe-lg-0 pt-lg-4 align-items-center rounded-3 border shadow-lg'>
					<Col className='col-lg-7 p-3 p-lg-5 pt-lg-3'>
						<h1 className='display-4 fw-bold lh-1'>New Student Enrollment</h1>
						<p className='lead'>
							If you are enrolling your child(ren) in a Diocese of Lincoln School <u>for the first time</u>, please click the button below and follow the instructions to fill out the Pre-Enrollment
							Form. If you are a new family and are enrolling multiple children please follow the instructions below and complete the Pre-Enrollment Form for each child.
						</p>
						<p class='lead'>Once your Pre-Enrollment Form is approved by the school you will receive an email with further instructions.</p>
						<div className='d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3'>
							<Button
								href='/enrollment/pre_enroll_instructions'
								target='_blank'
								variant='primary'
								className='px-4 px-4 me-md-2'
								size='lg'
							>
								New Student Enrollment Instructions
							</Button>
						</div>
					</Col>
					<Col className='col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg d-none d-lg-block'>
						<img
							className='rounded-lg-3 d-none d-lg-block'
							src={NewStudentImg}
							alt='New Student Site'
							width='720'
						/>
					</Col>
				</Row>
			</Container>
			<Container className='p-4'>
				<h2 className='mb-4'>Frequently Asked Questions</h2>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>What if I don't have a PowerSchool Parent Portal account?</Accordion.Header>
						<Accordion.Body className='py-5'>
							If you don't have a PowerSchool Parent Portal account, please contact your local school. Your school will provide you with a letter that contains your student's{' '}
							<strong>Access ID</strong> and <strong>Access Password</strong>. Once you have those two pieces of information, follow the guide on creating a{' '}
							<a href='enrollment/new_parent_portal_setup'>PowerSchool Parent Portal account</a>.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='1'>
						<Accordion.Header>What if I am not sure if I already have a Parent Portal account?</Accordion.Header>
						<Accordion.Body className='py-5'>
							<u>
								<strong>Do Not Create a New Parent Portal account</strong>
							</u>
							! If you you are unsure if you have PowerSchool Parent Portal account, please contact your local school. Your school will check to see if you have a Parent Portal account. If you do they
							will give you the <strong>username</strong> and <strong>email address</strong> associated with your account. If you do not have a Parent Portal account they will provide you with a
							letter that contains your student's <strong>Access ID</strong> and <strong>Access Password</strong> so that you can create a Parent Portal account by following the guide on creating a{' '}
							<a href='enrollment/new_parent_portal_setup'>PowerSchool Parent Portal account</a>.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='2'>
						<Accordion.Header>What if I don't know/cannot remember my PowerSchool Parent Portal account Username?</Accordion.Header>
						<Accordion.Body className='py-5'>
							If you do not remember your <strong>Username</strong> go to{' '}
							<a
								href='https://powershool.cdolinc.net'
								target='_blank'
								rel='noreferrer'
							>
								https://powershool.cdolinc.net
							</a>{' '}
							and click the <em>Forgot Username?</em> link under the <strong>Parent Account Recovery Links</strong> at the bottom of the screen. It will ask you to enter the{' '}
							<strong>email address</strong> associated with your PowerSchool Parent Portal account. Once entered, your <strong>username</strong> will be sent to you via that{' '}
							<strong>email address</strong>.
							<br />
							<img
								className='img-fluid p-3'
								src={ForgotUn}
								alt='Parent Forgot Password'
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='3'>
						<Accordion.Header>What if I don't know/cannot remember my PowerSchool Parent Portal account Password?</Accordion.Header>
						<Accordion.Body className='py-5'>
							<p>
								If you do not remember your <strong>Password</strong> go to{' '}
								<a
									href='https://powershool.cdolinc.net'
									target='_blank'
									rel='noreferrer'
								>
									https://powershool.cdolinc.net
								</a>{' '}
								and click the <em>Forgot Password?</em> link under the <strong>Parent Account Recovery Links</strong> at the bottom of the screen. It will ask you to enter your{' '}
								<strong>username</strong> and <strong>email address</strong> associated with your PowerSchool Parent Portal account. Once entered, you will be sent an email to reset your{' '}
								<strong>password</strong>.
							</p>
							<img
								className='img-fluid p-3'
								src={ForgotPw}
								alt='Parent Forgot Password'
							/>
							<p>
								<mark>
									<strong>Please Note</strong>:
								</mark>{' '}
								you will be forced to change the <strong>password</strong> immediately after entering your <em>temporary password</em> from the email. The <em>temporary password</em> should be typed
								in the <strong>Current Password</strong> field on the <u>Change Your Password screen</u>. Also once you change your <strong>password</strong>, you <strong> WILL NOT</strong> be logged
								into the the Parent Portal. Rather PowerSchool will take you back to the main PowerSchool login screen where you will have to enter your <strong>username</strong>
								and <em>new password</em> you just created to login.
							</p>
							<img
								className='img-fluid p-3'
								src={PwReset}
								alt='Parent  Password Reset'
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='4'>
						<Accordion.Header>What if I don't know/cannot remember my PowerSchool Parent Portal account Email Address?</Accordion.Header>
						<Accordion.Body className='py-5'>
							<p>
								If you do not remember your <strong>email address</strong> associated with your Parent Portal account, please contact your local school. Your school will be able to look up your Parent
								Portal account <strong>email address</strong>.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='5'>
						<Accordion.Header>What if I no longer have the Email Address Associated with my PowerSchool Parent Portal Account?</Accordion.Header>
						<Accordion.Body className='py-5'>
							<p>
								If you no longer have the <strong>email address</strong> associated with your Parent Portal account, please contact your local school and provied them with a <em>new email address</em>
								. Your school will be able to change your old <strong>email address</strong> with the <em>new email address</em> you provided them.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='6'>
						<Accordion.Header>What if my PowerSchool Parent Portal Account is locked after too many attempted logins?</Accordion.Header>
						<Accordion.Body className='py-5'>
							<p>
								If you have not been able to log in after 10 or more failed attempts PowerSchool will give you an error message and your account will be locked. If this occurs, please contact your
								local school and they will be able to unlock your account. The school will notify you when your account has been unlocked an you can attempt to log in again.
							</p>
							<img
								className='img-fluid p-3'
								src={TooMany}
								alt='Too many attempts'
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='7'>
						<Accordion.Header>My student is missing from my PowerSchool Parent Portal account?</Accordion.Header>
						<Accordion.Body className='py-5'>
							If you are missing a student in the blue bar, they are not associated with your account. Please contact your local school. The school will provide you with a letter that contains your
							student's <strong>Access ID</strong> and <strong>Access Password</strong>. Once you have those two pieces of information, follow the{' '}
							<a href='enrollment/add_parent_portal_setup'>guide</a> to add a child to your account.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='8'>
						<Accordion.Header>What if I have new and returning students?</Accordion.Header>
						<Accordion.Body className='py-5'>
							If you already have students in the Diocese of Lincoln and you also have a child who will attend a Diocese of Lincoln school for the first time this year, we suggest you go through the{' '}
							<a
								href='enrollment/returning_instructions'
								rel='noreferrer'
							>
								Returning Student Enrollment Instructions
							</a>{' '}
							for each returning student first. When completed with the returning students, then go through the <a href='/enrollment/pre_enroll_instructions'>Pre-Enrollment Instructions</a> for the
							new student attending the Diocese of Lincoln school for the first time next school year.
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Container>
		</>
	)
}
export default Enrollment

import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import DioceseLogo from '../assets/img/logo.svg'

const GlobalNav = () => {
	return (
		<Navbar
			collapseOnSelect
			expand='lg'
			bg='primary'
			variant='dark'
			className='cdol-navbar'
			sticky='top'
		>
			<Container>
				<LinkContainer to='/'>
					<Navbar.Brand>
						<img
							src={DioceseLogo}
							height='45'
							className='d-inline-block align-middle'
							alt='Diocese logo'
						/>
					</Navbar.Brand>
				</LinkContainer>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='me-auto'></Nav>
					<Nav>
						<LinkContainer to='/enrollment'>
							<Nav.Link className='fs-5'>Enrollment</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/Links'>
							<Nav.Link className='fs-5'>Links</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/lms'>
							<Nav.Link className='fs-5'>LMS</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/MFA'>
							<Nav.Link className='fs-5'>MFA</Nav.Link>
						</LinkContainer>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
export default GlobalNav

import { Container } from 'react-bootstrap'
import SubNav from '../../components/SubNav'
import ForgotPW from '../../assets/img/Enrollment/parent_portal_forgot.png'
import ParentPortalStudents from '../../assets/img/Enrollment/parent_portal_students.png'
import ParentPortalForms from '../../assets/img/Enrollment/parent_portal_forms.png'
import ParentPrefWarn from '../../assets/img/Enrollment/parent_pref_warn.png'
import ParentPrefs from '../../assets/img/Enrollment/parent_preferences.png'
import ParentPortalFormsStudent from '../../assets/img/Enrollment/parent_portal_forms_student.png'
import ParentPortalStudentForm from '../../assets/img/Enrollment/parent_portal_student_from.png'
import ParentPortalUpload from '../../assets/img/Enrollment/parent_portal_upload.png'
import ParentPortalUploadSuccess from '../../assets/img/Enrollment/parent_portal_upload_success.png'
import ParentThankYou from '../../assets/img/Enrollment/parent_thankyou.png'
import ParentSaveForFamily from '../../assets/img/Enrollment/parent_portal_save_for_family.png'
import ParentSaveorSubmit from '../../assets/img/Enrollment/parent_save_or_submit.png'
import ParentPortalPendingMessage from '../../assets/img/Enrollment/parent_portal_pending_message.png'
import ParentPortalFormsPending from '../../assets/img/Enrollment/parent_portal_forms_pending.png'
import ParentPortalFormsApproved from '../../assets/img/Enrollment/parent_portal_forms_approved.png'
import ParentPortalFormsRejected from '../../assets/img/Enrollment/parent_portal_forms_rejected.png'
import ParentPortalRejectedMessage from '../../assets/img/Enrollment/parent_portal_rejected_message.png'
import StudentLogin from '../../assets/img/Enrollment/student_login.png'
import Ribbon from '../../assets/img/Enrollment/ribbon.png'
import FormHeader from '../../assets/img/Enrollment/form_header.png'

const Instructions = ({ InstructionPath }) => {
	return (
		<>
			<SubNav />
			<Container className='mb-5 pt-4 full-width-div'>
				<h1>{InstructionPath} Enrollment Instructions</h1>
				{InstructionPath === 'Returning Student' ? (
					<p className='lead'>
						The following directions are intended for parents who need to re-enroll their child(ren) for the upcoming school year through the PowerSchool Parent Portal. If you are enrolling your child(ren) for the first time in a Diocese of Lincoln School, do not use this guide. Use the <a href='/enrollment/pre_enroll_instructions'>Pre-Enrollment Instructions</a> instead.
					</p>
				) : InstructionPath === 'New Student' ? (
					<p class='lead'>
						The following directions are intended for parents who are enrolling your child(ren) for the first time in a Diocese of Lincoln School <strong>AFTER</strong> receiving the approval email message from your school. If you are re-enrolling your child(ren) for the upcoming school year, do not use this guide. Use the <a href='/enrollment/returning_instructions'>Returning Student Instructions</a> instead.
					</p>
				) : (
					<p class='lead'>
						The following directions are intended for those who need to re-enroll their student for the upcoming school year through their <strong>student's</strong> PowerSchool student account. If you are enrolling your child(ren) for the first time in a Diocese of Lincoln School, do not use this guide. Use the <a href='/enrollment/pre_enroll_instructions'>Pre-Enrollment Student Instructions</a> instead.
					</p>
				)}
				<h2 className='pt-4'>Accessing Enrollment Forms</h2>
				<ol>
					<li>Open any internet browser on your computer (Chrome, Firefox, Edge, Etc.)</li>
					<li>
						Type{' '}
						<a
							href='https://powerschool.cdolinc.net/'
							target='_Blank'
							rel='noreferrer'
						>
							https://powerschool.cdolinc.net/
						</a>{' '}
						into the address bar
					</li>
					{InstructionPath === 'Student' ? (
						<>
							<li>
								Scroll to the <strong>Student Sign In</strong> section and click the <strong>Student Sign In</strong> button
							</li>{' '}
							<img
								class='img-fluid p-3'
								src={StudentLogin}
								alt='Student log in'
							/>
							<li>Then use the student's Office 365 email address and password to authenticate and sign in to PowerSchool </li>
						</>
					) : (
						<li>
							Type in the {InstructionPath === 'Student' ? <>Student's </> : ''}
							<strong>Username</strong> and <strong>Password</strong> used to log into the PowerSchool Account. Then click the <strong>Sign In</strong> button{' '}
						</li>
					)}
					<ul>
						<li>
							If you do not remember your {InstructionPath === 'Student' ? <>Student's </> : ''} <strong>Username</strong>{' '}
							{InstructionPath === 'Student' ? (
								<> please contact the School</>
							) : (
								<>
									click the <em>Forgot Username</em> link under the <strong>Parent Account Recovery Links</strong> section at the bottom of the screen
								</>
							)}
						</li>
						<li>
							If you do not remember your {InstructionPath === 'Student' ? <>Student's </> : ''} <strong>Password</strong>{' '}
							{InstructionPath === 'Student' ? (
								<> please contact the School</>
							) : (
								<>
									click the <em>Forgot Password</em> link under the <strong>Parent Account Recovery Links</strong> section at the bottom of the screen
								</>
							)}
						</li>
						{InstructionPath === 'Student' ? (
							<></>
						) : (
							<li>
								If you don't have a PowerSchool Parent Portal account, {InstructionPath === 'Returning Student' ? <> please contact your local school. They will provide you with a letter that </> : InstructionPath === 'New Student' ? <>please refer back to the approval email message from your school. This email </> : ''}
								contains your student's <strong>Access ID</strong> and <strong>Access Password</strong>. Once you have those two pieces of information, follow the guide on creating a <a href='new_parent_portal_setup'>PowerSchool Parent Portal account</a>.
							</li>
						)}
					</ul>
				</ol>
				{InstructionPath === 'Student' ? (
					<></>
				) : (
					<>
						<img
							class='img-fluid p-3'
							src={ForgotPW}
							alt='Parent Forgot Password'
						/>
						<ol start='4'>
							<li>
								Once on the PowerSchool Parent home screen you will see the students you have associated with your parent account in the blue bar at the top of the screen. Click on a student to begin the {InstructionPath === 'Returning Student' ? 're-' : ''}enrollment process.
								<ul>
									<li>
										If you are missing a student in the blue bar, they are not associated with your account.{' '}
										{InstructionPath === 'Student' ? (
											''
										) : (
											<>
												{' '}
												Please contact your school and they will provide you with a letter that contains your student's <strong>Access ID</strong> and <strong>Access Password</strong>. Once you have those two pieces of information, follow the guide on creating a <a href='new_parent_portal_setup'>PowerSchool Parent Portal account</a>
											</>
										)}
									</li>
								</ul>
							</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentPortalStudents}
							alt='Parent Portal Students'
						/>
					</>
				)}
				<ol start={InstructionPath === 'Student' ? '4' : '5'}>
					<li>
						Then click on <strong>Forms</strong> button toward the middle of the Left Navigation
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalForms}
					alt='Parent Portal Forms'
				/>
				{InstructionPath === 'Student' ? (
					<></>
				) : (
					<>
						<h3>Enrollment Preferences</h3>
						<ol start='6'>
							<li>
								The first time you access the <em>Forms</em> Screen you will be prompted with a message that says <em>Your preferences haven't been configured. To configure if and how you'll receive notifications for forms, please use the button below to set your preferences. </em>
							</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentPrefWarn}
							alt='Parent Preferences Warning'
						/>
						<ol start='7'>
							<li>
								Clicking on the gear button will open the Parent Preferences dialog. Here you can choose whether you wish to receive notifications and, if you do, to which email address(es) you would like them sent.
								<ul>
									<li>A form may be set up to send notifications to parents when it is submitted or when the status of a pending form changes.</li>
								</ul>
							</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentPrefs}
							alt='Parent Preferences'
						/>
					</>
				)}
				<h3>Filling Out Enrollment Forms</h3>
				<ol start={InstructionPath === 'Student' ? '5' : '8'}>
					<li>
						On the <em>Forms</em> Screen you will see a list of enrollment forms that need to be completed.
						<ul>
							<li>
								The progress bar above the forms will fill in{' '}
								<span
									className='fw-bold'
									style={{ color: 'green' }}
								>
									green
								</span>{' '}
								as you complete the individual forms.
							</li>
						</ul>
					</li>
					<li>
						Click on the first blue link in the list <em>(usually A. Student Info)</em> to be taken to the first enrollment form you need to complete.
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalFormsStudent}
					alt='Parent Portal Forms Student'
				/>
				<ol start={InstructionPath === 'Student' ? '7' : '10'}>
					<li>Above the form is the Form Ribbon. This allows you to move between forms in the enrollment process. Use the arrows on either end of the ribbon to scroll to see more forms.</li>
					<img
						class='img-fluid p-3'
						src={Ribbon}
						alt='Form Ribbon'
					/>
					<li>Just below the Form Ribbon is the Archive Header. If a form has been previously submitted, the archive header gives you the ability to view these submissions.</li>
					<img
						class='img-fluid p-3'
						src={FormHeader}
						alt='Form Header'
					/>
					<li>The rest of the page is the form itself. The form will be pre-populated with existing information the school has on file for your student. You need to confirm what the school has on file is correct, and update any information as needed.</li>
					<li>
						Fields with the{' '}
						<span
							className='fw-bold fs-1 align-middle px-1'
							style={{ color: 'red' }}
						>
							*
						</span>{' '}
						icon are required fields. They must be filled out in order for the form to be submitted.
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentPortalStudentForm}
					alt='Parent Portal Student Form'
				/>
				{InstructionPath === 'Student' ? (
					<></>
				) : (
					<>
						<h3>Uploading a Document</h3>
						<p>Some enrollment forms will require you to download additional documentation. These will be presented as hyperlinks that take you to a PDF file to download. You may need to download, print, sign, and scan before uploading these files. If unable to complete digitally, please drop off the required documents at the school office prior to the start of school</p>
						<ol start='14'>
							<li>
								To upload a document you will need to click the <strong>Upload</strong> button
							</li>
							<li>A side pop out window will open</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentPortalUpload}
							alt='Parent Portal Upload'
						/>
						<ol start='16'>
							<li>
								Click the <strong>Browse</strong> button
							</li>
							<li>This will open a file explorer on your device. Locate the file you need to upload and click it</li>
							<li>
								Back in the side pop out window click the{' '}
								<span
									className='fw-bold'
									style={{ color: 'green' }}
								>
									green
								</span>{' '}
								<strong>Upload</strong> at the bottom{' '}
							</li>
							<li>You will then get a File uploaded successfully message if every uploaded correctly</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentPortalUploadSuccess}
							alt='Upload success message'
						/>
					</>
				)}
				<h3>Submitting a Form</h3>
				<ol start={InstructionPath === 'Student' ? '11' : '20'}>
					<li>
						Once the form is filled out, press the <strong>Submit</strong> button at the bottom of the page. Your form will be submitted and reloaded. If the form submission was successful, a pop-up window will open thanking you for the submission.
					</li>
				</ol>
				<img
					class='img-fluid p-3'
					src={ParentThankYou}
					alt='Thank you'
				/>
				{InstructionPath === 'Student' ? (
					<>
						<ol start='12'>
							<li>Once the first form is Submitted it will take you to the next form in the list. Complete all Enrollment forms until they all have been finished</li>
						</ol>
					</>
				) : (
					<>
						<ol start='21'>
							<li>Next to the Submit button is an arrow that brings up a couple of options for submission.</li>
							<li>
								For some forms, the first may be <em>“Submit for Family”</em>. Choosing this option will allow you to choose which of your children you would like this submission to apply to. This is useful for a form that is not student specific. Using <em>“Submit for Family”</em> will change the values submitted on the form, for the other children associated with your account.
								<ul>
									<li>
										<em>“Submit for Family”</em> is only available on a few forms
									</li>
								</ul>
							</li>
							<li>
								The other option is to <em>“Submit & Jump to…”</em>. Below this option is a list of forms you can open after submitting this form.
							</li>
						</ol>
						<img
							class='img-fluid p-3'
							src={ParentSaveForFamily}
							alt='Submit for Family'
						/>
						<ol start='24'>
							<li>Repeat steps 8 - 14 for any additional child(ren) associated with your account.</li>
						</ol>
					</>
				)}
				<h3>Save for Later vs. Submit</h3>
				<p>
					Along with the <strong>Submit</strong> button is the <strong>Save for Later</strong> button.{' '}
				</p>
				<img
					class='img-fluid p-3'
					src={ParentSaveorSubmit}
					alt='Save or Submit'
				/>
				<p>
					Use the <strong>Save for Later</strong> button if you have entered data on the from but are unable to finish or submit the form at this time. PowerSchool will save any information already filled out so you do not have to re-enter later. It is recommended that you save a form if you have to step away from your computer, as PowerSchool may log you out for inactivity.
				</p>
				<p>Saved forms are saved locally on your computer. You can access the saved entry from your same user on the same browser. You will not be able to access the saved data from another device. A form with a saved entry will appear Empty in the form listing.</p>
				<p>
					When to Save of Later:
					<ul>
						<li>You need to step away from the computer</li>
						<li>You do not have all the required information but have filled out some of the information on a form</li>
					</ul>
				</p>
				<p>
					When to Submit:
					<ul>
						<li>You are ready to send the form to the school for approval</li>
					</ul>
				</p>
				<h3>Approval</h3>
				<p>All forms require a school administrator to verify the information before it is submitted. After submission the form will go into a pending state until the administrator approves or rejects the submission. A pop-up window will open on submission, informing you the form is pending approval.</p>
				<img
					class='img-fluid p-3'
					src={ParentPortalPendingMessage}
					alt='Pending Message'
				/>
				<p>
					The form will have a{' '}
					<span
						className='fw-bold'
						style={{ color: 'goldenrod' }}
					>
						yellow
					</span>{' '}
					background and edited fields that need approval will have a red flag beside them. Hovering over the flag will show the current and previous response for that field. On the Forms page, a pending form will have the status of{' '}
					<span
						className='fw-bolder'
						style={{ color: '#0075DB' }}
					>
						<em>Pending Review</em>
					</span>{' '}
					with a{' '}
					<span
						className='fw-bold'
						style={{ color: '#0075DB' }}
					>
						blue
					</span>{' '}
					incomplete circle with checkmark icon.
				</p>
				<img
					class='img-fluid p-3'
					src={ParentPortalFormsPending}
					alt='Pending'
				/>
				<p>
					If the form is approved, the status will be{' '}
					<span
						className='fw-bolder'
						style={{ color: '#3E8D21' }}
					>
						<em>Approved</em>
					</span>{' '}
					with a{' '}
					<span
						className='fw-bold'
						style={{ color: '#3E8D21' }}
					>
						green
					</span>{' '}
					circle with a checkmark.
				</p>
				<img
					class='img-fluid p-3'
					src={ParentPortalFormsApproved}
					alt='Approved'
				/>
				<p>
					If the response to the form was rejected, he status will be{' '}
					<span
						className='fw-bold'
						style={{ color: '#CB1010' }}
					>
						<em>Rejected</em>
					</span>{' '}
					with a{' '}
					<span
						className='fw-bold'
						style={{ color: '#CB1010' }}
					>
						red
					</span>{' '}
					circle with a X icon.
				</p>
				<img
					class='img-fluid p-3'
					src={ParentPortalFormsRejected}
					alt='Rejected'
				/>
				<p>
					Clicking the form name will display the form with a{' '}
					<span
						className='fw-bold'
						style={{ color: '#CB1010' }}
					>
						red
					</span>{' '}
					background. A pop-up window will open, letting you know the form was rejected. If the administrator indicated a reason the form was rejected, that reason will also display on the pop-up.
				</p>
				<img
					class='img-fluid p-3'
					src={ParentPortalRejectedMessage}
					alt='Rejected Message'
				/>
				<p>Click the Edit Rejected button to be able to edit your submission. Once you finish making your changes, resubmit the form. It will return to pending status.</p>
				<p>
					If the form is set up to send parent notifications and you have enabled them on your account, you will receive an email when a form's status moves from <em>Pending</em> to <em>Approved</em> or <em>Rejected</em>.
				</p>
			</Container>
		</>
	)
}
export default Instructions

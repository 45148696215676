import MFAHelpInstructions from "./MFAHELP_INSTRUCTIONS";
import { useEffect } from "react";

const MFAHelp_Token = () => {
	useEffect(() => {
		document.title = "MFA Help | Token | Diocese of Lincoln Schools";
	});

	return <MFAHelpInstructions MFAHelpPath="MFA Token" />;
};

export default MFAHelp_Token;

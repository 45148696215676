import { Col, Card } from 'react-bootstrap'
const UserCard = ({ user }) => {
	return (
		<Col key={user.name}>
			<Card className='p-3 rounded-3 border shadow'>
				<div className='d-flex align-items-center'>
					<div className='image'>
						{user.img ? (
							<img
								src={user.img ? require(`../assets/img/Users/${user.img}`) : ''}
								className='rounded-3 shadow'
								width='155'
								alt={user.name}
							/>
						) : (
							<></>
						)}
					</div>
					<div className='ms-3 w-100'>
						<h4 className='my-0 card-title'>{user.name}</h4>
						<h6 className='card-subtitle my-1 text-muted'>{user.title}</h6>
						<p className='card-text'>{user.ext ? <small>Ext: {user.ext}</small> : ''}</p>
					</div>
				</div>
			</Card>
		</Col>
	)
}
export default UserCard

import { Container, Navbar, Nav, Button } from 'react-bootstrap'
import { IoArrowBackCircle } from 'react-icons/io5'
import { BsFillPrinterFill } from 'react-icons/bs'
import { LinkContainer } from 'react-router-bootstrap'
const SubNav = () => {
	return (
		<Navbar
			variant='light'
			bg='light'
			sticky='top'
			className='border-bottom p-0 m-0 sticky-offset-subnav cdol-navbar'
		>
			<Container>
				<Nav className='me-auto'>
					<LinkContainer to='..'>
						<Nav.Link>
							<Button
								variant='outline-primary'
								size='sm'
							>
								<IoArrowBackCircle className='me-1 align-center fs-5' />
								Back
							</Button>
						</Nav.Link>
					</LinkContainer>
				</Nav>
				<Nav>
					<Nav.Link onClick={e => window.print()}>
						<Button
							variant='outline-primary'
							size='sm'
						>
							Print
							<BsFillPrinterFill className='ms-2 align-center fs-5' />
						</Button>
					</Nav.Link>
				</Nav>
			</Container>
		</Navbar>
	)
}
export default SubNav

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const Links = () => {
    useEffect(() => {
        document.title = 'Links | Diocese of Lincoln Schools'
    })

    return (
        <>
            <Container className='bg-light text-secondary p-1 text-center'>
				<h1 className='text-primary'>Links</h1>
				<Col className='col-lg-8 mx-auto lead'>
				<p className='lead'>
					Whether you are new to the Diocese or just need a friendly reminder, here are some of the most commonly used apps and sites in the Diocese of Lincoln Schools. 
				</p>
				</Col>
			</Container>
            <Container>
            <iframe src="https://start.me/p/nRzQAG/ed-tech-lab-homepage?embed=1" name="startme" scrolling="auto" frameborder="0" marginheight="0px" marginwidth="0px" height="1024" width="100%" allowfullscreen></iframe>
            </Container>
        </>
    )
}

export default Links
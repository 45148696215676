import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
const Notfound = () => {
	useEffect(() => {
		document.title = '404 Not Found'
	})
	return (
		<Container className='bg-light text-secondary p-1 text-center'>
			<h1 className='text-primary pt-3 display-6 fw-bold lh-1'>404 Page Not Found!</h1>
		</Container>
	)
}
export default Notfound

import Instructions from './Instructions'
import { useEffect } from 'react'

const StudentInstructions = () => {
	useEffect(() => {
		document.title = 'Student Enrollment Instructions | Diocese of Lincoln Schools'
	})

	return <Instructions InstructionPath='Student' />
}
export default StudentInstructions

import Instructions from './Instructions'
import { useEffect } from 'react'

const ReturningInstructions = () => {
	useEffect(() => {
		document.title = 'Returning Student Enrollment Instructions | Diocese of Lincoln Schools'
	})

	return <Instructions InstructionPath='Returning Student' />
}
export default ReturningInstructions

import Instructions from './Instructions'
import { useEffect } from 'react'

const NewInstructions = () => {
	useEffect(() => {
		document.title = 'New Student Enrollment Instructions | Diocese of Lincoln Schools'
	})

	return <Instructions InstructionPath='New Student' />
}
export default NewInstructions

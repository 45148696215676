import MFAHelpInstructions from "./MFAHELP_INSTRUCTIONS";
import { useEffect } from "react";

const MFAHelp_Lockout = () => {
	useEffect(() => {
		document.title = "MFA Help | Lockout | Diocese of Lincoln Schools";
	});

	return <MFAHelpInstructions MFAHelpPath="MFA Lockout" />;
};

export default MFAHelp_Lockout;

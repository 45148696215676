import { Container } from 'react-bootstrap'
import ParentPortalLogin from '../../assets/img/Enrollment/parent_portal_login.png'
import ParentPortalCreate from '../../assets/img/Enrollment/parent_portal_create.png'
import ParentPortalUser from '../../assets/img/Enrollment/parent_portal_user.png'
import ParentPortalLink from '../../assets/img/Enrollment/parent_portal_link.png'
import { useEffect } from 'react'
import SubNav from '../../components/SubNav'

const ParentPortal = () => {
	useEffect(() => {
		document.title = 'New Parent Portal Setup | Diocese of Lincoln Schools'
	})

	return (
		<>
			<SubNav />
			<Container className='mb-5 pt-4 full-width-div'>
				<>
					<h1>PowerSchool Parent Portal Setup</h1>
					<p className='lead'>
						The following directions are intended for parents who would like to create a new PowerSchool Parent Account
						<br />
						The parent portal is an online portal accessible anywhere on the web. Parents can log in to and see all of their children in one place, and monitor grades, assignments, scores, attendance,
						schedules, and school bulletins for each school your children attend.
						<br />
						If you already have a PowerSchool Parent Portal account and are adding a new student to your Account, Please follow this link to the <br />{' '}
						<a href='add_parent_portal_setup'>Adding Student to Existing PowerSchool Parent Account</a> page.
					</p>
					<h2 className='pt-4'>Creating a New PowerSchool Parent Account</h2>
					<ol>
						<li>Open any internet browser on your computer (Chrome, Firefox, Edge, Etc.)</li>
						<li>
							Type{' '}
							<a
								href='https://powerschool.cdolinc.net/'
								target='_Blank'
								rel='noreferrer'
							>
								https://powerschool.cdolinc.net/
							</a>{' '}
							into the address bar
						</li>
						<li>
							Click the <strong>Create Account</strong> tab
						</li>
					</ol>
					<img
						class='img-fluid p-3'
						src={ParentPortalLogin}
						alt='Parent Portal Login'
					/>
					<ol start='4'>
						<li>
							On the <em>Create an Account</em> screen, click the <strong>Create Account</strong> button
						</li>
					</ol>
					<img
						class='img-fluid p-3'
						src={ParentPortalCreate}
						alt='Parent Portal Create'
					/>
					<ol start='5'>
						<li>
							On the <em>Create Parent Account</em> screen, fill in the fields to create your account
							<ol>
								<li>
									<strong>First Name</strong> - You, the <u>parent/guardian's</u> first name
								</li>
								<li>
									<strong>Last Name</strong> - You, the <u>parent/guardian's</u> last name
								</li>
								<li>
									<strong>Email</strong> - the email address you want to associate with the PowerSchool Parent Portal account
									<ol>
										<li>This email address will be used for all notifications from the PowerSchool Parent Portal account</li>
									</ol>
								</li>
								<li>
									<strong>Re-enter Email</strong> - Re-enter the email address listed above
								</li>
								<li>
									<strong>Desired Username</strong> - Create a unique username that you will use to log in
								</li>
								<li>
									<strong>Password</strong> - Create a password that matches the following requirements
									<ul>
										<li>Be at least 8 characters long</li>
										<li>Contain at least one uppercase and one lowercase letter</li>
										<li>Contain at least one letter and one number</li>
										<li>Contain at least one special character</li>
									</ul>
								</li>
							</ol>
						</li>
					</ol>
					<img
						class='img-fluid p-3'
						src={ParentPortalUser}
						alt='Parent Portal User'
					/>
					<ol start='6'>
						<li>
							Next move to the Link Students to Account section
							<br />
							<br />
							<p>
								You should have received a letter from your school with your student's <strong>Access ID</strong> and <strong>Access Password</strong>. You will need that letter for the next step. If
								you don't have your student's <strong>Access ID</strong> and <strong>Access Password</strong>, please contact your local school. They will provide you with a letter that contains your
								student's <strong>Access ID</strong> and <strong>Access Password</strong>.
							</p>
							<ol>
								<li>
									<strong>Student Name</strong> - Type the <u>Student's</u> name
									<ul>
										<li>LastName, FirstName works best</li>
										<li>
											It should also recognize FirstName <em>space</em> LastName
										</li>
									</ul>
								</li>
								<li>
									<strong>Access ID</strong> - Type the <u>Access ID</u> for your student from the letter given from your school
									<ul>
										<li>If you do not have this letter, please contact your school.</li>
									</ul>
								</li>
								<li>
									<strong>Access Password </strong> - Type the <u>Access Password</u> for your student from the letter given from your school
									<ul>
										<li>If you do not have this letter, please contact your school.</li>
									</ul>
								</li>
								<li>
									<strong>Relationship</strong> - Choose your relationship to the student from the dropdown
								</li>
							</ol>
						</li>
						<li>Repeat Step 6 for each student you want to add to your account</li>
						<li>
							When you have finished adding all your students click the <strong>Enter</strong> button at the bottom of the page
						</li>
						<li>This will take you back to the PowerSchool Parent Account log on screen</li>
						<li>
							Log in with the <strong>Username</strong> and <strong>Password</strong> you just created to access the parent portal
							<ul>
								<li>
									<u>Not</u> the student's Access ID and Access Password
								</li>
							</ul>
						</li>
					</ol>
					<img
						class='img-fluid p-3'
						src={ParentPortalLink}
						alt='Parent Portal Link'
					/>
				</>
			</Container>
		</>
	)
}
export default ParentPortal

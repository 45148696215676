import { useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import SubNav from '../../components/SubNav'
import NewStudentImg from '../../assets/img/Enrollment/newstudent_site.png'
import PreEnrollMessage from '../../assets/img/Enrollment/pre-enroll_thankyou.png'
import PreEnrollEmail from '../../assets/img/Enrollment/pre-enroll_email.png'
import pdfFile from '../../assets/pdf/pre-enrollment_approval_email.pdf'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

const PreEnroll = () => {
	useEffect(() => {
		document.title = 'Pre-Enrollment Instructions | Diocese of Lincoln Schools'
	})
	return (
		<>
			<SubNav />
			<Container className='mb-5 pt-4 full-width-div'>
				<h1>Pre-Enrollment Instructions</h1>
				<p class='lead'>
					The following directions are intended for parents who are enrolling your child(ren) for the first time in a Diocese of Lincoln School, You will first need to fill out the Pre-enrollment
					form. If you are a new family and are enrolling multiple children please complete the Pre-Enrollment form for each child. Once your Pre-Enrollment form is approved by the school you will
					receive an email with further instructions.
				</p>
				<Container
					id='new'
					className='my-2'
				>
					<Row className='pt-lg-2 align-items-center rounded-3 border shadow-sm'>
						<Col className='col-lg-7 p-1 p-lg-5 pt-lg-3'>
							<h2 className=''>Step 1: Pre-Enrollment Form</h2>
							<p className='lead'>
								Your first step will be to fill out the Pre-Enrollment form. This form collects the essential information needed for your school to start the enrollment process. Click the Button below
								to open the Pre-Enrollment form
							</p>
							<div className='d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3'>
								<Button
									href='https://powerschool.cdolinc.net/public/formbuilder/form.html?formid=198789'
									target='_blank'
									variant='primary'
									className='px-4 px-4 me-md-2'
									size='lg'
								>
									Pre-Enrollment Form
								</Button>
							</div>
						</Col>
						<Col className='col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg d-none d-lg-block'>
							<img
								className='rounded-lg-3 d-none d-lg-block'
								src={NewStudentImg}
								alt='New Student Site'
								width='720'
							/>
						</Col>
					</Row>
				</Container>
				<h2 className='pt-4'>Step 2: Submitting the Pre-Enrollment Form</h2>
				<ol type='a'>
					<li>Completely fill out all the necessary fields on the form</li>
					<li>
						Fields with the{' '}
						<span
							className='fw-bold fs-1 align-middle px-1'
							style={{ color: 'red' }}
						>
							*
						</span>{' '}
						icon are required fields. They must be filled out in order for the form to be submitted.
					</li>
					<li>
						When finished click the blue <strong>Submit</strong> button at the bottom of the screen
					</li>
					<li>A modal will pop open with this message </li>
					<img
						class='img-fluid p-3 rounded-3 border shadow-sm my-3'
						src={PreEnrollMessage}
						alt='Parent Portal Login'
					/>
					<li>
						Shortly after you should also receive and email from <strong>PowerSchool - Diocese of Lincoln Schools</strong> <em>(powerschool@cdolinc.net)</em> that looks like this
					</li>
					<img
						class='img-fluid p-3 rounded-3 border shadow-sm my-3'
						src={PreEnrollEmail}
						alt='Parent Portal Login'
					/>
					<li>
						<mark>At this point you will need to wait until the school approves your application</mark>
					</li>
				</ol>
				<h2 className='pt-4'>Step 3: Post-Approval Steps</h2>
				<ol
					type='a'
					className='py-4'
				>
					<li>
						Once the school approves your application, you will receive an email with further instructions (like the email example below) on how to create your PowerSchool Parent Portal Account and
						how to complete the remaining enrollment forms, or you can follow these same <a href='/enrollment/new_student_instructions'>New Student Enrollment Instructions</a>
					</li>
				</ol>
				<h5>Example of what the approval email will look like:</h5>
				<div>
					<Worker
						className='rounded-3 border shadow-sm'
						workerUrl='https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js'
					>
						<Viewer
							fileUrl={pdfFile}
							defaultScale={1.6}
						/>
					</Worker>
				</div>
			</Container>
		</>
	)
}
export default PreEnroll

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import SubNav from "../../components/SubNav";
import { useEffect } from "react";
import A3 from "../../assets/img/MFA/MFA_QR_Android.png";
import A4 from "../../assets/img/MFA/MFA_QR_iOS.png";
import Step2 from "../../assets/img/MFA/Step2_MFA.gif";
import Step3 from "../../assets/img/MFA/Step3_MFA_Mobile_Final.gif";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const MFAAuthSetup = () => {
	useEffect(() => {
		document.title =
			"Authenticator Setup Instructions | Diocese of Lincoln Schools";
	});

	return (
		<>
			<SubNav />
			<Container className="bg-light text-secondary p-1 text-center">
				<h1 className="text-primary">Authenticator Application Setup</h1>
				<Col className="col-lg-8 mx-auto lead">
					<p className="lead">
						For the best user experience and a wide range of authentication
						options, we highly recommend the Microsoft Authenticator App. With
						its passwordless, push notifications, and code-based modes, it
						provides superior functionality. While alternative authenticator
						apps are available, they may not offer the same extensive feature
						set as Microsoft Authenticator.
					</p>
				</Col>
			</Container>
			<Container className="mb-4">
				<Tabs
					defaultActiveKey="install"
					id="Step 1: Install MS Authenticator"
					className="mb-4 mt-4 nav-pills flex-column flex-sm-row bg-light shadow-lg"
					justify
				>
					<Tab
						eventKey="install"
						title="Step 1: Install Authenticator"
						className="fw-bold"
					>
						<Container className="lead p-flex text-center">
							<Col className="p-2">
								On your <b>mobile device</b>{" "}
								<a href="https://go.microsoft.com/fwlink/?linkid=2160819">
									aquire the Microsoft Authenticator app
								</a>
								. Scan the QR code or tap a button below to get the app.
							</Col>
							<Container className="p-flex justify-content-center text-center">
								<Row className="py-2">
									<Col className="p-flex d-grid">
										<Row className="text-center">
											<h3>Google Play</h3>
										</Row>
										<Row className="h-auto w-50 px-4 py-2 mx-auto img-fluid">
											<Image
												src={A3}
												alt="Microsoft Authenticator app Android Download QR"
											></Image>
										</Row>
										<Button
											href="https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=%20adjust_reftag%3Dc6f1p4ErudH2C%26utm_source%3DLanding%2BPage%2BOrganic%2B-%2Bapp%2Bstore%2Bbadges%26utm_campaign%3Dappstore_android"
											variant="primary"
											className="px-4 mx-auto"
											size="sm"
										>
											Download Now on the Play Store
										</Button>
									</Col>
									<Col className="p-flex d-grid">
										<Row className="text-center">
											<h3>App Store</h3>
										</Row>
										<Row className="h-auto w-50 px-4 py-2 mx-auto img-fluid">
											<Image
												src={A4}
												alt="Microsoft Authenticator app iOS Download QR"
											></Image>
										</Row>
										<Button
											href="https://apps.apple.com/app/microsoft-authenticator/id983156458"
											variant="primary"
											className="px-4 mx-auto"
											size="sm"
										>
											Download Now on the App Store
										</Button>
									</Col>
								</Row>
							</Container>
						</Container>
					</Tab>
					<Tab
						eventKey="enable"
						title="Step 2: Enable MFA"
						className="fw-bold"
					>
						<Container className="lead p-2 p-flex">
							<Row>
								<ol type="A">
									<li>
										On your <b>PC or tablet</b> sign in to your Microsoft 365
										account dashboard by visiting{" "}
										<a
											href="https://mysignins.microsoft.com/security-info"
											target="blank"
										>
											https://mysignins.microsoft.com/security-info
										</a>
										.
									</li>
									<li>
										Select <b>add sign-in method</b> and choose method{" "}
										<b>Authenticator App</b>.
									</li>
									<li>
										Follow the instructions on screen until a QR Code appears.
									</li>
								</ol>
							</Row>
							<Row>
								<Image
									src={Step2}
									className="h-auto w-75 img-fluid"
								></Image>
							</Row>
						</Container>
					</Tab>
					<Tab
						eventKey="finish"
						title="Step 3: Scan and Finalize"
						className="fw-bold"
					>
						<Container className="lead p-2 p-flex">
							<Row className="row-cols-1 row-cols-lg-3 align-items-stretch">
								<Col className="col-lg-8">
									<ol type="A">
										<li>
											On your <b>mobile device</b> open the Authenticator App
											and, if prompted, <b>allow notifications</b>.
										</li>
										<ul>
											<li>
												Please note, notifications must be enabled to complete
												setup & receive codes. Additionally, you must allow
												access to the camera to scan the QR code.
											</li>
											<li>
												If you have denied any of these permissions you can
												change them in your device settings.
											</li>
										</ul>
										<li>
											Next, add an account, and select <b>work or school</b>.
										</li>
										<li>
											When prompted
											<mark>
												<b> choose scan QR code</b>
											</mark>
											. Using the<b> Authenticator App </b>scan the QR code that
											appears on your<b> PC or tablet screen</b>.
										</li>
										<li>
											<mark>
												<b>
													On your PC, continue following the instructions on
													screen.
												</b>
											</mark>
										</li>
										<ul>
											<li>
												You may be asked to enter a code or match a number to
												test the setup.
											</li>
											<li>
												If unable to scan, you will need to enter the code and
												URL manually.
											</li>
											<li>
												The six digit code will be displayed on the
												Authenticator and refreshes every 30 seconds. Microsoft
												is moving away from using six-digit codes in favor of
												the two-digit number matching method. Either method is
												acceptable.
											</li>
										</ul>
									</ol>
									<p>
										To learn how to use MFA and how to backup your authenticator
										visit our <a href="/MFA/MFAHelp">MFA Help page </a>for more
										information.
									</p>
								</Col>
								<Col className="col-lg-4">
									<Image
										src={Step3}
										className="d-flex mx-auto img-fluid h-auto w-75"
									></Image>
								</Col>
							</Row>
						</Container>
					</Tab>
				</Tabs>
			</Container>
		</>
	);
};
export default MFAAuthSetup;

import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Users from "../data/users.json";
import UserCard from "../components/UserCard";

const Home = () => {
	useEffect(() => {
		document.title = "Education Technology | Diocese of Lincoln Schools";
	});
	return (
		<>
			<Container className="bg-light text-secondary p-1 text-center mb-2">
				<h1 className="text-primary display-5 m-0 p-0">
					Education Technology Office
				</h1>
				<h2 className="text-primary fw-light m-0 p-0">
					Diocese of Lincoln Schools
				</h2>
				<h3 className="text-primary fw-lighter m-0 p-0">Lincoln, Nebraska</h3>
				<p className="lead m-0 p-0">
					Tel:{" "}
					<a
						className="link-unstyled"
						href="tel:+14024730600"
					>
						(402) 473-0600
					</a>{" "}
					| Fax: (402) 488-6525
				</p>
			</Container>
			<Container className="mb-5">
				<Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
					{Users.map((user) => {
						return <UserCard user={user} />;
					})}
				</Row>
			</Container>
			<Container className="bg-light py-1 text-center mb-4">
				<p className="lead m-0 p-0">
					To request technical support or to request hardware or software:{" "}
					<br />
					Log on to:{" "}
					<a
						target="_blank"
						href="https://cdol.jitbit.com"
						rel="noreferrer"
					>
						Diocesan Help Desk
					</a>
				</p>
			</Container>
		</>
	);
};
export default Home;

import { Container, Accordion } from 'react-bootstrap'
import { useEffect } from 'react'
import SubNav from '../../components/SubNav'
const MoblieApp = () => {
	useEffect(() => {
		document.title = 'Canvas LMS Mobile Setup Instructions | Diocese of Lincoln Schools'
	})

	return (
		<>
			<SubNav />
			<Container className='bg-light text-secondary p-1 text-center'>
				<h1 className='text-primary pt-3 display-6 fw-bold lh-1'>Canvas LMS Mobile App Setup Instructions</h1>
				<p className='lead'>
					The following directions will assist you in selecting the correct app and configuring your device.These instructions do not demonstrate how to create a parent account. For those instructions
					please visit the <a href='parent_account_setup'>parent account setup page</a>.
				</p>
			</Container>
			<Container className='p-4'>
				<h2 className='mb-4'>Select Your Role</h2>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Parent / Guardian</Accordion.Header>
						<Accordion.Body className='py-5'>
							<ol>
								<li>Download the app.</li>
								<ul>
									<li>
										<a
											target='_blank'
											href='https://apps.apple.com/us/app/canvas-parent/id1097996698?itsct=apps_box_badge&amp;itscg=30200'
											rel='noreferrer'
										>
											Apple Devices - Tap Here to Open the App Store
										</a>
									</li>
									<li>
										<a
											target='_blank'
											href='https://play.google.com/store/apps/details?id=com.instructure.parentapp&amp;hl=en_US&amp;gl=US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
											rel='noreferrer'
										>
											Android Devices - Tap Here to Open the Google Play Store
										</a>
									</li>
								</ul>
								<li>Open the app and tap 'Find my School'</li>
								<li>
									Start searching for <strong>'Diocese of Lincoln Schools'</strong>
								</li>
								<li>
									Choose Diocese of Lincoln Schools - <strong>Parents</strong>
								</li>
								<li>Login or create an account using the instructions above.</li>
							</ol>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='1'>
						<Accordion.Header>Student</Accordion.Header>
						<Accordion.Body className='py-5'>
							<ol>
								<li>Download the app.</li>
								<ul>
									<li>
										<a
											target='_blank'
											href='https://apps.apple.com/us/app/canvas-student/id480883488?itsct=apps_box_badge&amp;itscg=30200'
											rel='noreferrer'
										>
											Apple Devices - Tap Here to Open the App Store
										</a>
									</li>
									<li>
										<a
											target='_blank'
											href='https://play.google.com/store/apps/details?id=com.instructure.candroid&amp;hl=en_US&amp;gl=US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
											rel='noreferrer'
										>
											Android Devices - Tap Here to Open the Google Play Store
										</a>
									</li>
								</ul>
								<li>Open the app and tap 'Find my School'</li>
								<li>
									Start searching for <strong>'Diocese of Lincoln Schools'</strong>
								</li>
								<li>
									Choose Diocese of Lincoln Schools - <strong>Teachers/Students</strong>
								</li>
								<li>Login using your Microsoft 365 credentials</li>
							</ol>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey='2'>
						<Accordion.Header>Faculty / Staff</Accordion.Header>
						<Accordion.Body className='py-5'>
							<ol>
								<li>Download the app.</li>
								<ul>
									<li>
										<a
											target='_blank'
											href='https://apps.apple.com/us/app/canvas-teacher/id1257834464?itsct=apps_box_badge&amp;itscg=30200'
											rel='noreferrer'
										>
											Apple Devices - Tap Here to Open the App Store
										</a>
									</li>
									<li>
										<a
											target='_blank'
											href='https://play.google.com/store/apps/details?id=com.instructure.teacher&amp;hl=en_US&amp;gl=US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
											rel='noreferrer'
										>
											Android Devices - Tap Here to Open the Google Play Store
										</a>
									</li>
								</ul>
								<li>Open the app and tap 'Find my School'</li>
								<li>
									Start searching for <strong>'Diocese of Lincoln Schools'</strong>
								</li>
								<li>
									Choose Diocese of Lincoln Schools - <strong>Teachers/Students</strong>
								</li>
								<li>Login using your Microsoft 365 credentials</li>
							</ol>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Container>
		</>
	)
}
export default MoblieApp

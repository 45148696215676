import {
	Container,
	Row,
	Col,
	Card,
	Button,
	Image,
	Tab,
	Tabs,
} from "react-bootstrap";
import { useEffect } from "react";
import MFA1 from "../../assets/img/MFA/MFA_Landing_Updated_2C.png";
import MFANumberMatch from "../../assets/img/MFA/MFA_Number_Match.png";

const MFA = () => {
	useEffect(() => {
		document.title = "Multifactor Authentication | Diocese of Lincoln Schools";
	});

	return (
		<>
			<Container className="bg-light text-secondary p-1 text-center">
				<h1 className="text-primary">Multifactor Authentication - MFA</h1>
				<Col className="col-lg-8 mx-auto lead">
					<p className="lead">
						Traditional passwords are now easily captured by cyber attackers,
						making compromised passwords a common way for them to access your
						data, identity, or email. MFA is a simple and effective way to
						increase security.
					</p>
				</Col>
			</Container>
			<Container className="p-2">
				<Row className="align-items-center d-flex flex-column g-2 py-1">
					<Col className="col-lg-8 text-center">
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Title className="pt-2 mt-2 display-6 lh-1 fw-bold">
								Setup MFA
							</Card.Title>
							<Card.Body className="d-flex flex-column h-100 p-3 text-black text-shadow-1">
								<p className="lead">
									For mobile device setup choose{" "}
									<b>authenticator application</b>.
								</p>
								<p className="lead">
									If you were issued a token choose <b>hardware token</b>
								</p>
								<Col className="d-grid gap-2 d-md-flex justify-content-md-center p-2">
									<Button
										href="/MFA/MFA_AuthSetup"
										variant="primary"
										className="px-4 px-4 me-md-2"
										size="lg"
									>
										Authenticator Application
									</Button>
									<Button
										href="/MFA/MFA_Hardware"
										variant="primary"
										className="px-4 px-4 me-md-2"
										size="lg"
									>
										Hardware Token
									</Button>
									<Button
										href="/MFA/MFAHelp"
										variant="primary"
										className="px-4 px-4 me-md-2"
										size="lg"
									>
										Troubleshooting & Help
									</Button>
								</Col>
								<Col className="align-items-center d-flex flex-lg-row-reverse flex-column g-3 py-2">
									<Image
										src={MFA1}
										className="mx-lg-auto img-fluid"
										alt="Multifactor Authentication Diagram"
									></Image>
								</Col>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<Container className="lead p-2">
				<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
					<Card.Body className="d-flex flex-column h-100 pt-2 p-3 text-black text-shadow-1">
						<Tabs
							defaultActiveKey="what"
							id="MFA Explained"
							className="mb-4 mt-4"
							justify
						>
							<Tab
								eventKey="what"
								title="What is MFA?"
								className="fw-bold"
							>
								<Container>
									<p className="lead">
										MFA works like a lock with two or more keys - a password or
										pin (something you know), a phone or card (something you
										have), and potentially biometrics (something like your
										fingerprint). Using MFA ensures that only you can access
										your account and not anyone who might attempt to steal your
										information.
									</p>
									<Row className="ratio ratio-16x9">
										<iframe
											src="https://www.youtube.com/embed/nc7fpGJsE1g"
											title="Making accounts more secure with multi-factor authentication"
											allowfullscreen
										></iframe>
									</Row>
								</Container>
							</Tab>
							<Tab
								eventKey="how"
								title="How do I use MFA?"
							>
								<Container>
									<p className="lead">
										If you've ever entered your username and password and been
										asked for a code that is emailed or texted to you, then you
										are already familiar with the process.
									</p>
									<p>
										Say you are going to check your email at home so you enter
										your work email and password. <u>Without MFA</u> that's all
										you would need, and <u>anyone</u> with your credentials
										could log in.{" "}
										<b>
											With MFA enabled when signing in from an unfamiliar or new
											device, such as a new phone or pc, you will be prompted to
											enter a six digit code or matching number, to verify your
											identity.
										</b>
									</p>
									<ul>
										<li>
											If you are using the Microsoft Authenticator app, you
											simply open the app on your mobile device and it will show
											you a 6-digit dynamically created number or it will ask
											you to match a two digit number. Enter either of these
											numbers and you're in.
										</li>
										<li>
											If you are using a hardware authenticator the process is
											similar. Just push the button on the hardware
											authenticator to display the 6-digit one-time password
											(OTP) number and enter it into the site.
										</li>
									</ul>
									<p className="lead">Here's what that may look like.</p>
									<Image
										src={MFANumberMatch}
										className="mx-lg-auto img-fluid"
										alt="Multifactor Authentication Number Matching Example"
									></Image>
									<p className="lead">How often will I be asked for a code?</p>
									<ul>
										<li className="lead">
											Typically, only when you login from a new device or
											location outside of the school network. MFA works by
											recoginizing new devices or locations. If you use the same
											computer and mobile device on a regular basis, MFA will
											remember these devices and you will rarely be asked for a
											code.{" "}
										</li>
										<li className="lead">For example:</li>
										<ul>
											<li className="lead">
												You got a new computer at home and attempt to log in to
												Office 365. You will be prompted to enter a code.
											</li>
											<li className="lead">
												You log in through a friend or family member's computer.
												You will be prompted to enter a code.
											</li>
										</ul>
									</ul>
								</Container>
							</Tab>
							<Tab
								eventKey="learn"
								title="Learn More"
							>
								<Container>
									<p className="lead">
										For more information on MFA and how you can protect your
										digital accounts please visit:
										<ul>
											<li>
												<a href="https://www.cisa.gov/resources-tools/resources/multifactor-authentication-mfa">
													Cybersecurity & Infrastructure Security Agency: MFA
												</a>
											</li>
											<li>
												<a href="https://support.microsoft.com/en-us/topic/what-is-multifactor-authentication-e5e39437-121c-be60-d123-eda06bddf661">
													Microsoft Support: What is Multifactor Authentication?
												</a>
											</li>
											<li>
												<a href="https://www.youtube.com/watch?v=nc7fpGJsE1g&list=PL3ZTgFEc7LyvwfUMGoDa8T-dZCLl5dwBh">
													Security 101 for Everyday Users - Microsoft Security
													on Youtube
												</a>
											</li>
											<li>
												<a href="https://www.knowbe4.com/multi-factor-authentication">
													KnowBe4: Multi-Factor Authentication
												</a>
											</li>
											<li>
												<a href="https://support.microsoft.com/en-us/account-billing/work-or-school-account-help-718b3d92-a8a7-4656-8a05-c0228d346b7d">
													Work or School Account Help
												</a>
											</li>
											<li>
												<a href="https://support.microsoft.com/en-us/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a">
													Microsoft Authenticator app instructions for Work or
													School accounts.
												</a>
											</li>
										</ul>
									</p>
									<Row className="ratio ratio-16x9">
										<iframe
											src="https://www.youtube.com/embed/LB2yj4HSptc"
											title="Authentication Methods"
											allowfullscreen
										></iframe>
									</Row>
								</Container>
							</Tab>
						</Tabs>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

export default MFA;

import MFAHelpInstructions from "./MFAHELP_INSTRUCTIONS";
import { useEffect } from "react";

const MFAHelp_FAQ = () => {
	useEffect(() => {
		document.title = "MFA Help | FAQ | Diocese of Lincoln Schools";
	});

	return <MFAHelpInstructions MFAHelpPath="MFA FAQ" />;
};

export default MFAHelp_FAQ;

import { Container } from 'react-bootstrap'
import CanvasParentRegistration from '../../assets/img/LMS/Canvas-Parent-Registration.png'
import CanvasParentRegistration2 from '../../assets/img/LMS/Canvas-Parent-Registration-2.png'
import CanvasParentPwReset from '../../assets/img/LMS/Canvas-Parent-PW-Reset-Steps.png'
import { useEffect } from 'react'
import SubNav from '../../components/SubNav'

const ParentAccount = () => {
	useEffect(() => {
		document.title = 'Canvas LMS Parent Account Instructions | Diocese of Lincoln Schools'
	})

	return (
		<>
			<SubNav />
			<Container className='bg-light text-secondary p-1 text-center'>
				<h1 className='text-primary pt-3 display-6 fw-bold lh-1'>Canvas LMS Parent Account Setup Instructions</h1>
				<p className='lead'>
					A Canvas Parent account is required to observe a student and to use the mobile app. The following directions are intended for parents or guardians who have not yet setup their Canvas LMS
					account. If you have already setup your Canvas LMS account, please click{' '}
					<a
						href='https://cdol.instructure.com/login/canvas'
						rel='noreferrer'
					>
						here
					</a>{' '}
					to login.
				</p>
			</Container>
			<Container>
				<h2 className='pt-4'>Pre-Requisites for an Account</h2>
				<ul>
					<li>
						<strong>Email Address</strong>
					</li>
					<ul>
						<li>If you are a parent AND a faculty or staff member please create a separate parent account with a different email address than your teacher / work email address.</li>
					</ul>
					<li>
						<strong>Student Pairing Code</strong>
					</li>
					<ul>
						<li>Pairing codes are six-digit codes that are used to link students to observers in Canvas. Pairing codes are alphanumeric and case sensitive.</li>
						<li>
							Pairing codes can be generated by a student or on behalf of a student by another user with the appropriate permission, such as the teacher or school technology liaison. Once generated,
							the pairing code can only be used to link the student who created the code or had the code created on their behalf.
						</li>
						<li>
							<strong>Additional pairing codes must be created for each student that will be linked to an observer.</strong>
						</li>
					</ul>
				</ul>
			</Container>
			<Container>
				<h2 className='pt-4'>Register for an Account</h2>
				<ol>
					<li>Open any internet browser on your computer or phone (Chrome, Firefox, Edge, Safari, etc.)</li>
					<li>
						Type or click the following address{' '}
						<a
							href='https://cdol.instructure.com/login/canvas'
							target='_Blank'
							rel='noreferrer'
						>
							https://cdol.instructure.com/login/canvas
						</a>{' '}
						into the address bar
					</li>
					<li>
						In the upper right corner click the <strong>"Parent of a Canvas user?"</strong> button to begin setting up your account.
					</li>
				</ol>
				<img
					className='img-fluid p-3'
					src={CanvasParentRegistration}
					alt='Canvas Parent Registration Screenshot'
				/>
				<ol start='4'>
					<li>
						On the next window enter your name, email address, a password of your choice, and the pairing code for the student you want to observe.
						<ul>
							<li>Don't forget to verify the reCAPTCHA checkbox.</li>
						</ul>
					</li>
				</ol>
				<img
					className='img-fluid p-3'
					src={CanvasParentRegistration2}
					alt='Canvas Parent Registration Screenshot 2'
				/>
			</Container>
			<Container>
				<h2 className='pt-4'>Can I link to multiple students using pairing codes as an observer?</h2>
				<ul>
					<li>
						You can link to multiple students as an observer, as long as you use a unique pairing code for each student. When creating an account, you will only be able to enter one pairing code.
						Additional pairing codes can be entered from your User Settings.
					</li>
				</ul>
				<h2 className='pt-4'>Where can I read more about student pairing codes?</h2>
				<ul>
					<li>
						More information can be found by visiting the{' '}
						<a
							target='_blank'
							href='https://community.canvaslms.com/t5/Canvas-Resource-Documents/Pairing-Codes-FAQ-PDF/ta-p/388738'
							rel='noreferrer'
						>
							Canvas Pairing Codes-FAQ
						</a>
					</li>
				</ul>
			</Container>
			<Container>
				<h2 className='pt-4'>Can I reset my parent account password?</h2>
				<ul>
					<li>
						Yes, on the <a href='https://cdol.instructure.com/login/canvas'>login</a> page you can click <strong>"Forgot Password?"</strong> to reset your password.
					</li>
					<li>
						Additional information and detailed instructions can be found <a href='https://community.canvaslms.com/t5/Observer-Guide/How-do-I-reset-my-password-as-an-observer/ta-p/570'>here.</a>
					</li>
				</ul>
				<img
					className='img-fluid p-3'
					src={CanvasParentPwReset}
					alt='Parent Password Reset Screenshot'
				/>
			</Container>
		</>
	)
}
export default ParentAccount

import {
	Container,
	Row,
	Col,
	Button,
	Tab,
	Tabs,
	Image,
	Card,
	Nav,
} from "react-bootstrap";
import SubNav from "../../components/SubNav";
import { useEffect } from "react";

const MFAHelp = () => {
	useEffect(() => {
		document.title = "MFA Troubleshooting & Help | Diocese of Lincoln Schools";
	});

	return (
		<>
			<SubNav />
			<Container className="bg-light text-secondary p-1 text-center">
				<h1 className="text-primary">Help & Troubleshooting</h1>
				<Col className="col-lg-8 mx-auto lead">
					<p className="lead">
						Find solutions to the most common MFA issues, and learn how to
						backup and transfer Authenticator to a new device.
					</p>
				</Col>
			</Container>
			<Container>
				<Row className="row-cols-1 row-cols-lg-2 align-items-stretch g-5 py-5">
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									New Phone? Backup or Restore Authenticator
								</Card.Title>
								<Card.Body className="lead">
									Planning on getting a new phone? Learn how to backup
									authenticator on your old phone.
								</Card.Body>
								<Button
									href="MFAHelp/mfahelp_backup"
									className="m-2"
								>
									Learn More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									Locked Out
								</Card.Title>
								<Card.Body className="lead">
									Locked out of your account? Learn how to reset your MFA and
									regain access to your account.
								</Card.Body>
								<Button
									href="MFAHelp/mfahelp_lockout"
									className="m-2"
								>
									Learn More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									Frequently Asked Questions
								</Card.Title>
								<Card.Body className="lead">
									Read through our FAQ to find answers to common questions.
								</Card.Body>
								<Button
									href="MFAHelp/mfahelp_faq"
									className="m-2"
								>
									Learn More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="card-cover h-100 overflow-hidden text-black bg-light shadow-lg">
							<Card.Body className="d-flex flex-column h-100 p-5 text-black text-shadow-1">
								<Card.Title className="pt-2 mt-2 mb-4 display-6 lh-1 fw-bold">
									MFA Token Help
								</Card.Title>
								<Card.Body className="lead">
									Learn more about token-based MFA and how to use it.
								</Card.Body>
								<Button
									href="MFAHelp/mfahelp_token"
									className="m-2"
								>
									Learn More
								</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default MFAHelp;
